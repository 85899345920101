import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
// import ProductListing from '../components/ProductListing';
// import LatestBlogposts from '../components/Blog/LatestBlogposts';
// import FeaturedCollections from '../components/FeaturedCollections';
import PageBody from '../components/PageBody';

const HomePage = ({ data, pageContext, location }) => {
  const {
    prismicHome: { data: page },
  } = data;
  return (
    <>
      <SEO />
      {/* <FeaturedCollections /> */}
      {/* <ProductListing /> */}
      {/* <LatestBlogposts /> */}
      <PageBody document={page} />
    </>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default HomePage;

export const data = graphql`
  query($lang: String!) {
    prismicHome(lang: { eq: $lang }) {
      data {
        title {
          html
        }
        body {
          ... on PrismicHomeBodyCta {
            id
            slice_type
            primary {
              action_label
              heading {
                html
              }
              subheading
              action_url {
                target
                url
                type
                uid
              }
            }
          }
          ... on PrismicHomeBodyHero {
            id
            slice_type
            primary {
              action_label
              action_url {
                url
                uid
                type
                target
              }
              align
              heading {
                html
              }
              pre_heading
              subheading
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 550) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyImageCta {
            id
            slice_type
            primary {
              action_label
              action_url {
                url
                uid
                type
                target
              }
              align
              heading {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 550) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyGridNav {
            id
            slice_type
            items {
              action_label
              action_url {
                url
                uid
                type
                target
              }
              heading {
                html
              }
              subheading
            }
          }
          ... on PrismicHomeBodyMetrics {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
            items {
              metric
              value
            }
          }
          ... on PrismicHomeBodyLogosStrip {
            id
            slice_type
            items {
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 180, height: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyTextBlock {
            id
            slice_type
            primary {
              heading {
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicHomeBodyActionBar {
            id
            slice_type
            primary {
              label
              action_label
              action_url {
                url
                uid
                type
                target
              }
              person {
                document {
                  ... on PrismicPerson {
                    id
                    data {
                      name
                      title
                      photo {
                        localFile {
                          childImageSharp {
                            fixed(width: 60, height: 60) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyProductGrid {
            id
            slice_type
            primary {
              heading {
                html
              }
              subheading
            }
            items {
              product {
                type
                uid
                document {
                  ... on PrismicProductBox {
                    id
                    data {
                      title {
                        html
                      }
                      subtitle
                      price
                      ribbon
                      image {
                        localFile {
                          childImageSharp {
                            fixed(width: 200, height: 200) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      action_label
                      action_url {
                        uid
                        type
                        url
                        target
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyVideo {
            id
            slice_type
            primary {
              action_label
              heading {
                html
              }
              video_url {
                html
              }
            }
          }
          ... on PrismicHomeBodyColumns {
            id
            slice_type
            items {
              heading {
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicHomeBodyCompare {
            id
            slice_type
            primary {
              title_left {
                html
              }
              title_right {
                html
              }
            }
            items {
              feature_left {
                html
              }
              feature_right {
                html
              }
              image_left {
                localFile {
                  childImageSharp {
                    fixed(width: 180, height: 180) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              image_right {
                localFile {
                  childImageSharp {
                    fixed(width: 180, height: 180) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              description_left
              description_right
            }
          }
          ... on PrismicHomeBodyHeading {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
          }
          ... on PrismicHomeBodyParagraph {
            id
            slice_type
            primary {
              content {
                html
              }
            }
          }
        }
      }
    }
  }
`;
